<template>
  <div class="businessNotification">
    <!-- 分组菜单 -->
    <div class="menu">
      <notification-menu
        title="分组"
        :menuData="menuData2"
        :defaultKey="groupId"
        @add="addGroup"
        @del="delGroup"
        @menuItemClick="groupMenuClick"
        v-if="menuData2.length > 0"
      >
        <span
          slot="operation"
          slot-scope="{ record }"
          @click.stop="editGroup(record)"
          >编辑</span
        >
      </notification-menu>
    </div>
    <!-- 添加分组、修改分组弹窗 -->
    <add-group
      v-if="groupVisible"
      :groupVisible.sync="groupVisible"
      :groupList="menuData2"
      :groupInfo="currentGroup"
      @refresh="refresh"
    ></add-group>
    <!-- 删除分组弹窗 -->
    <del-group
      :delGroupVisible.sync="delGroupVisible"
      :groupList="menuData2"
      :groupId="groupId"
      v-if="delGroupVisible"
      @refresh="refresh"
    ></del-group>
    <!-- 消息通知场景菜单 -->
    <div class="menu">
      <notification-menu
        title="消息通知场景"
        :menuData="menuData1"
        :defaultKey="sceneId"
        @add="addScene"
        @del="delScene"
        @menuItemClick="sceneMenuClick"
      >
        <!-- <span slot="operation_0">导出</span> -->
        <!-- <span slot="operation_1" @click.stop="allocation">分配</span> -->
        <span
          slot="operation"
          slot-scope="{ record }"
          @click.stop="EditScene(record)"
          >编辑</span
        >
      </notification-menu>
    </div>
    <!-- 分配场景穿梭框 -->
    <allocation
      title="场景分配"
      :visible.sync="allocationVisible"
      :scenarioId="sceneId"
      v-if="allocationVisible"
      @refresh="refresh"
    ></allocation>
    <div class="content">
      <!-- 场景标识 -->
      <div class="content_key">
        <div>
          <span class="scenarioKey"> 场景标识（scenarioKey）</span>
          <span v-if="!isModify" class="editKey"
            >{{ scenarioKey
            }}<a-icon
              type="edit"
              style="
                color: #1890ff;
                cursor: pointer;
                font-size: 20px;
                margin-left: 10px;
              "
              @click="EditKey"
          /></span>
          <span v-else class="editKey">
            <a-input v-model="scenarioKey"></a-input>
            <a-icon
              type="check"
              style="
                font-size: 20px;
                color: #68b468;
                margin-right: 15px;
                margin-left: 15px;
              "
              @click="modifyKey"
            />
            <a-icon
              type="close"
              style="font-size: 20px; color: #ff6868"
              @click="cancelEdit"
            />
          </span>
        </div>
      </div>
      <!-- 添加、编辑场景弹窗 -->
      <scene
        :sceneVisible.sync="sceneVisible"
        :sceneData="sceneData"
        :groupId="groupId"
        v-if="sceneVisible"
        @refresh="refresh"
      ></scene>
      <!-- 删除场景弹窗 -->
      <del-scene
        :delSceneVisible.sync="delSceneVisible"
        :sceneList="menuData1"
        :sceneId="sceneId"
        v-if="delSceneVisible"
        @refresh="refresh"
      ></del-scene>
      <!-- 场景内容 -->
      <div class="content_tab" v-if="individuationData">
        <a-tabs
          @change="tabChange"
          pagination="false"
          :defaultActiveKey="tabKey ? tabKey : '1'"
        >
          <a-tab-pane key="1" tab="渠道">
            <a-button type="primary" class="button" @click="addChannel"
              >添加渠道</a-button
            >
            <a-table
              :columns="columns1"
              :data-source="chancelData"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <!-- 通知方式 -->
              <div slot="channel" slot-scope="text, record">
                <!-- <span v-if="text == 'EMAIL'">邮箱</span>
                <span v-if="text == 'HUAWEI_SMS'">华为云短信</span>
                <span v-if="text == 'TENCENT_SMS'">腾讯云短信</span>
                <span v-if="text == 'WX_MP'">微信公众号</span> -->
                <span>{{ ChannelName[text] }}</span>
              </div>
              <!-- 模板 -->
              <div slot="content" slot-scope="text">
                <span class="hidden_text">{{ text ? text : '---' }}</span>
              </div>
              <!-- 开关 -->
              <div slot="status" slot-scope="text, record, index">
                <!-- :checked="record.status == 'DISABLED' ? false : true" -->
                <a-switch
                  :checked="record.switch"
                  @change="switchChange($event, record, index)"
                />
              </div>
              <!-- 编辑 -->
              <div slot="operation" slot-scope="text, record">
                <a @click="editTemplate(record)">编辑模板</a>
              </div>
            </a-table>
            <!-- 编辑模板弹窗 -->
            <edit-template
              :templateVisible.sync="templateVisible"
              :templateData="templateData"
              :variableData="variableData"
              :scenarioId="sceneId"
              v-if="templateVisible"
              @refresh="refresh"
              type="business"
            ></edit-template>
          </a-tab-pane>
          <a-tab-pane key="2" tab="变量">
            <a-button type="primary" class="button" @click="addVariable"
              >添加变量</a-button
            >
            <!-- 添加变量 -->
            <add-variable
              :visible.sync="variableVisible"
              :scenarioId="sceneId"
              v-if="variableVisible"
              @refresh="refresh"
            ></add-variable>
            <a-table
              :columns="columns2"
              :data-source="variableData"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <div slot="operation" slot-scope="text, record">
                <a @click="delVariable(record)">删除</a>
              </div>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="签名和邮箱个性化">
            <a-button type="primary" class="button" @click="addIndividuation"
              >添加</a-button
            >
            <!-- 个性化 -->
            <a-table
              :columns="columns3"
              :data-source="individuationData"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <!-- 短信签名 -->
              <div slot="sms" slot-scope="text, record">
                {{ record.sms.tencentSignatureName }}
              </div>

              <!-- 邮件服务器 -->
              <div slot="smtp" slot-scope="text, record">
                {{ record.smtp.host + ':' + record.smtp.port }}
              </div>

              <!-- 公众号是否配置 -->
              <div slot="enable" slot-scope="text">
                {{ text ? '已配置' : '未配置' }}
              </div>

              <div slot="operation" slot-scope="text, record">
                <a
                  v-if="!record.scenarioIds.includes(sceneId)"
                  @click="allot(record)"
                  >分配</a
                >
                <a v-else @click="unassign(record)">取消分配</a>
                <a-divider type="vertical" />
                <a @click="editIndividuation(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="delIndividuation(record)">删除</a>
              </div>
            </a-table>
            <!-- 编辑个性化设置弹窗 -->
            <edit-individuation
              :individuationRecord="individuationRecord"
              :individuationVisible.sync="individuationVisible"
              :scenarioId="sceneId"
              type="business"
              v-if="individuationVisible"
              @refresh="refresh"
            ></edit-individuation>
          </a-tab-pane>
          <a-tab-pane key="4" tab="分配给租户">
            <a-button type="primary" class="button" @click="allocation"
              >分配</a-button
            >

            <a-table
              :columns="columns4"
              :data-source="alreadyTenantArr"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <div slot="operation" slot-scope="text, record">
                <a @click="delPermission(record)">删除</a>
              </div>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationMenu from './components/notificationMenu.vue'
import Scene from './modal/Scene.vue'
import delScene from './modal/delScene.vue'
import editTemplate from './modal/editTemplate.vue'
import editIndividuation from './modal/editIndividuation.vue'
import addGroup from './modal/addGroup.vue'
import delGroup from './modal/delGroup.vue'
import allocation from './components/allocation.vue'
import addVariable from './modal/addVariable.vue'
import getScrollHeight from '@/utils/scrollHeight.js'

const columns1 = [
  {
    title: '通知方式',
    key: 'channel',
    dataIndex: 'channel',
    scopedSlots: { customRender: 'channel' },
    width: '150px',
  },
  {
    title: '模板',
    key: 'content',
    dataIndex: 'content',
    scopedSlots: { customRender: 'content' },
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
    width: '75px',
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
    width: '150px',
  },
]

const columns2 = [
  {
    title: '字段',
    key: 'description',
    dataIndex: 'description',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '表达式',
    key: 'name',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

const columns3 = [
  {
    title: '租户ID',
    key: 'tenantId',
    dataIndex: 'tenantId',
    scopedSlots: { customRender: 'tenantId' },
  },
  {
    title: '租户',
    key: 'tenantName',
    dataIndex: 'tenantName',
    scopedSlots: { customRender: 'tenantName' },
  },
  {
    title: '短信签名',
    key: 'sms',
    dataIndex: 'sms',
    scopedSlots: { customRender: 'sms' },
  },
  {
    title: '邮件服务器',
    key: 'smtp',
    dataIndex: 'smtp',
    scopedSlots: { customRender: 'smtp' },
  },
  {
    title: '公众号配置信息',
    key: 'enable',
    dataIndex: 'enable',
    scopedSlots: { customRender: 'enable' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
  },
]

const columns4 = [
  {
    title: '租户ID',
    key: 'id',
    dataIndex: 'tenantId',
    scopedSlots: { customRender: 'tenantId' },
  },
  {
    title: '租户',
    key: 'tenantName',
    dataIndex: 'tenantName',
    scopedSlots: { customRender: 'tenantName' },
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
  },
]

const chancelData = [
  {
    informMethod: '站内信',
    template: '--',
    status: true,
  },
  {
    informMethod: '短信',
    template: '--',
    status: true,
  },
  {
    informMethod: '微信',
    template: '--',
    status: true,
  },
  {
    informMethod: '邮件',
    template: '--',
    status: true,
  },
]

const variableData = [
  {
    field: 'code',
    expression: '${code}',
  },
]

const individuationData = [
  {
    appId: '2364827',
    appName: 'NIUESS',
    smsName: 'NIUESS',
    email: 'info@qq.com',
    officialAccount: '已配置',
  },
]

export default {
  name: 'BusinessNotification',
  components: {
    NotificationMenu,
    Scene,
    delScene,
    editTemplate,
    editIndividuation,
    addGroup,
    delGroup,
    allocation,
    addVariable,
  },
  inject: ['reload'],
  mixins: [getScrollHeight],
  data() {
    return {
      menuData1: [],
      menuData2: [],
      columns1,
      chancelData,
      columns2,
      variableData,
      columns3,
      individuationData,
      columns4,
      scenarioKey: '234789672398475237498',
      isModify: false,
      sceneVisible: false,
      sceneData: {},
      delSceneVisible: false,
      templateVisible: false,
      individuationVisible: false,
      groupVisible: false,
      delGroupVisible: false,
      allocationVisible: false,
      variableVisible: false,
      individuationRecord: {},
      groupId: '',
      sceneId: '',
      templateData: {},
      tabKey: '',
      editPre: '',
      channelList: ['TENCENT_SMS', 'HUAWEI_SMS', 'WX_MP', 'EMAIL'],
      ChannelName: {
        TENCENT_SMS: '腾讯云',
        HUAWEI_SMS: '华为云',
        WX_MP: '微信公众号',
        EMAIL: '邮箱',
        JIGUANG: '极光',
        IM: '站内信',
      },
      isEditGroup: false,
      currentGroup: {},
      allTenantList: [],
      alreadyTenantArr: [],
    }
  },
  created() {
    this.init()
    // this.tabKey = this.$store.state.notification.tabKey
    // this.groupId = this.$store.state.notification.groupMenuKey
    // this.sceneId = this.$store.state.notification.sceneMenuKey
    // this.scenarioKey = this.$store.state.notification.scenarioKey
    // if (this.$store.state.notification.groupMenuKey) {
    //   this.getBusinessNotificationGroupList().then(() => {
    //     this.getBusinessNotificationSceneList(
    //       this.$store.state.notification.groupMenuKey
    //     ).then((res) => {
    //       this.sceneId = this.$store.state.notification.sceneMenuKey
    //       /* 判断场景是否已经被删除 */
    //       let sceneIdList = res.map((item) => {
    //         return (item = item.scenarioId)
    //       })
    //       if (sceneIdList.includes(this.sceneId)) {
    //         this.sceneData = res.filter((item) => {
    //           return item.scenarioId == this.sceneId
    //         })[0]
    //         this.scenarioKey = this.sceneData.scenarioKey
    //         this.getTemplateList(this.sceneId)
    //         this.getVariableList(this.sceneId)
    //         this.getCustomizationList(this.sceneId)
    //       } else {
    //         this.sceneId = sceneIdList[0]
    //         this.sceneData = res[0]
    //         this.scenarioKey = res[0].scenarioKey
    //         this.getTemplateList(this.sceneId)
    //         this.getVariableList(this.sceneId)
    //         this.getCustomizationList(this.sceneId)
    //       }
    //     })
    //   })
    // } else {
    //   this.getBusinessNotificationGroupList().then(() => {
    //     this.groupId = this.menuData2[0].groupId
    //     this.getBusinessNotificationSceneList(this.menuData2[0].groupId).then(
    //       () => {
    //         this.sceneData = this.menuData1[0]
    //         this.sceneId = this.menuData1[0].scenarioId
    //         this.scenarioKey = this.menuData1[0].scenarioKey
    //         this.getTemplateList(this.sceneId)
    //         this.getVariableList(this.sceneId)
    //         this.getCustomizationList(this.sceneId)
    //       }
    //     )
    //   })
    // }
  },
  beforeDestroy() {
    /* 保留局部刷新前的位置状态 */
    this.savePosition()
  },
  watch: {
    menuData1: {
      handler(newValue, oldValue) {
        if (newValue.length == 0) {
          this.chancelData = []
          this.variableData = []
          this.individuationData = []
          this.sceneId = ' '
          this.tabKey = 1
          this.scenarioKey = ' '
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    /* 租户列表 */
    async getAllTenantList() {
      this.allTenantList = []
      let res = await this.$api._getTenantInfoList({ page: 1, size: 10 })
      if (res.code == 0) {
        let total = res.data.totalElements
        let time = Math.ceil(total / 100)
        let timeArr = [...Array(time).keys()].map((item) => item + 1)

        console.log(timeArr, 'timeArr')
        for (let item of timeArr) {
          let _res = await this.$api._getTenantInfoList({
            // size: res.data.totalElements,
            size: 100,
            // page: 1,
            page: item,
          })
          console.log(item, 'item')
          if (_res.code == 0)
            this.allTenantList = [...this.allTenantList, ..._res.data.content]
          if (item == time) this.getAlreadyAllocationTenantList()
        }

        // this.getAlreadyAllocationTenantList()
      }
    },
    /* 获取该场景下已分配的用户列表 */
    getAlreadyAllocationTenantList() {
      return new Promise((resolve, reject) => {
        this.$api
          .getAlreadyAllocationTenantList(this.sceneId)
          .then((res) => {
            if (res.code === 0) {
              this.alreadyTenantArr = res.data.map((item) => {
                let tenantName = this.allTenantList.filter(
                  (_item) => _item.id == item.tenantId
                )[0].name
                return {
                  tenantId: item.tenantId,
                  permissionId: item.permissionId,
                  tenantName,
                }
              })
              console.log(this.alreadyTenantArr, 'alreadyTenantArr')
              console.log(this.allTenantList, 'allTenantList')

              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 保留局部刷新前的位置状态方法 */
    savePosition() {
      let position = {
        groupMenuKey: this.groupId,
        sceneMenuKey: this.sceneId,
        tabKey: this.tabKey,
        scenarioKey: this.scenarioKey,
      }
      this.$store.commit('notification/savePosition', position)
    },
    refresh() {
      this.templateVisible = false
      this.variableVisible = false
      this.individuationVisible = false
      this.sceneVisible = false
      this.delSceneVisible = false
      this.groupVisible = false
      this.delGroupVisible = false
      this.allocationVisible = false
      this.init()
    },
    /* 添加渠道 */
    addChannel() {
      this.templateData = {}
      this.templateVisible = true
    },
    init() {
      if (this.groupId) {
        this.getBusinessNotificationGroupList().then(() => {
          /* 判断分组是否已经删除 */
          let groupIdArr = this.menuData2.map((item) => item.groupId)
          /* 未删除 */
          if (groupIdArr.includes(this.groupId)) {
            this.getBusinessNotificationSceneList(this.groupId).then((res) => {
              // this.sceneId = this.$store.state.notification.sceneMenuKey
              /* 判断场景是否已经被删除 */
              let sceneIdList = res.map((item) => {
                return (item = item.scenarioId)
              })
              if (sceneIdList.includes(this.sceneId)) {
                this.sceneData = res.filter((item) => {
                  return item.scenarioId == this.sceneId
                })[0]
                this.scenarioKey = this.sceneData.scenarioKey
                this.getTemplateList(this.sceneId)
                this.getVariableList(this.sceneId)
                this.getCustomizationList(this.sceneId)
                this.getAllTenantList()
              } else {
                this.sceneId = sceneIdList[0]
                this.sceneData = res[0]
                this.scenarioKey = res[0].scenarioKey
                this.getTemplateList(this.sceneId)
                this.getVariableList(this.sceneId)
                this.getCustomizationList(this.sceneId)
                this.getAllTenantList()
              }
            })
          } else {
            /* 已删除 */
            this.groupId = ''
            this.init()
          }
        })
      } else {
        this.getBusinessNotificationGroupList().then(() => {
          this.groupId = this.menuData2[0].groupId
          this.getBusinessNotificationSceneList(this.menuData2[0].groupId).then(
            () => {
              this.sceneData = this.menuData1[0]
              this.sceneId = this.menuData1[0].scenarioId
              this.scenarioKey = this.menuData1[0].scenarioKey
              this.getTemplateList(this.sceneId)
              this.getVariableList(this.sceneId)
              this.getCustomizationList(this.sceneId)
              this.getAllTenantList()
            }
          )
        })
      }
    },

    /* 更新个性化的分配场景 */
    updateCustomizationOfScene(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateCustomization(data)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 获取客制化（个性化配置）列表数据 */
    getCustomizationList(scenarioId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getCustomizationList(scenarioId)
          .then((res) => {
            if (res.code === 0) {
              this.individuationData = res.data
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 更新模板数据（状态） */
    updateTemplate(templateId, modifyData) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateTemplate(templateId, modifyData)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              resolve()
            }
          })
          .catch((err) => {
            this.$message.error('修改失败')
            console.log(err, '更新模板报错')
            reject()
          })
      })
    },

    /* 删除变量 */
    delVariableOfScene(variableId) {
      this.$api
        .deleteVariableOfScene(variableId)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('删除成功')
            // this.reload()
            this.refresh()
          }
        })
        .catch((err) => {
          this.$message.error('删除失败')
        })
    },

    /* 根据场景获取变量列表 */
    getVariableList(scenarioId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getVariableByScenarioId(scenarioId)
          .then((res) => {
            if (res.code === 0) {
              this.variableData = res.data
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 删除场景下已分配的租住 */
    delPermission(record) {
      console.log(record, 'deletePermission-record')
      let _this = this
      this.$confirm({
        title: '确定要删除分配吗？',
        onOk() {
          _this.$api
            .cancelAllocationScene([record.permissionId])
            .then((res) => {
              if (res.code == 0) {
                _this.$message.success('删除成功')
                // _this.reload()
                _this.refresh()
              }
            })
            .catch((err) => {
              _this.$message.error('删除失败')
              console.log(err, '删除失败')
            })
        },
        onCancel() {},
      })
    },

    /* 根据场景获取模板列表（渠道） */
    getTemplateList(scenarioId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getTemplateByScenarioId(scenarioId)
          .then((res) => {
            if (res.code === 0 && res.data.length > 0) {
              this.chancelData = res.data
              this.chancelData.forEach((item) => {
                this.$set(item, 'switch', item.status != 'DISABLED')
              })
              resolve()
            } else if (res.code === 0 && res.data.length === 0) {
              /* 发送添加默认模板请求 */
              Promise.all(this.addTemplates(scenarioId)).then((res) => {
                /* 添加完成有，在此发起获取模板请求 */
                this.getTemplateList(scenarioId)
              })
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 获取业务推送-场景列表 */
    getBusinessNotificationSceneList(groupId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getNotificationScene(groupId)
          .then((res) => {
            if (res.code === 0) {
              this.menuData1 = res.data
              this.menuData1.forEach((item) => {
                this.$set(item, 'id', item.scenarioId)
              })
              resolve(res.data)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 获取业务消息推送-分组列表 */
    getBusinessNotificationGroupList() {
      return new Promise((resolve, reject) => {
        this.$api
          .getBusinessNotificationGroupList()
          .then((res) => {
            if (res.code === 0) {
              this.menuData2 = res.data
              this.menuData2.forEach((item) => {
                this.$set(item, 'id', item.groupId)
              })
              this.menuData2.shift()
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 分组菜单项带点击 */
    groupMenuClick(key) {
      // console.log(key, '分组Id')
      this.groupId = key
      this.getBusinessNotificationSceneList(key).then(() => {
        this.sceneId = this.menuData1[0].scenarioId
        this.scenarioKey = this.menuData1[0].scenarioKey
        this.sceneData = this.menuData1[0]
        this.getTemplateList(this.sceneId)
        this.getVariableList(this.sceneId)
        this.getCustomizationList(this.sceneId)
        this.getAllTenantList()
      })
    },

    /* 场景菜单点击 */
    sceneMenuClick(key) {
      this.sceneId = key
      /* 当前选中场景的数据 */
      this.sceneData = this.menuData1.filter((item) => {
        return item.scenarioId == key
      })[0]

      this.scenarioKey = this.sceneData.scenarioKey
      this.getTemplateList(key)
      this.getVariableList(this.sceneId)
      this.getCustomizationList(this.sceneId)
      this.getAllTenantList()
    },

    /* 添加默认模板 */
    addDefaultTemplate(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .addTemplateChannel(data)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject()
          })
      })
    },
    /* 添加四个默认模板 */
    addTemplates(scenarioId) {
      return this.channelList.map((item) => {
        let data = {
          channel: item,
          content: '',
          scenarioId: scenarioId,
          status: 'DISABLED',
          thirdPartContent: '',
          thirdPartSignature: '',
          thirdPartTemplateId: '',
          variableIds: [],
        }
        return (item = this.addDefaultTemplate(data))
      })
    },

    /* ------------------------------------------------------------------------------------------ */
    /* 开关 */
    switchChange(checked, record, index) {
      console.log(record, '开关项')
      this.chancelData[index].switch = checked
      /* 发送更新模板状态请求 */
      let { state, templateId, ...data } = record
      let _data = {
        status: checked ? 'ENABLED' : 'DISABLED',
      }
      console.log(data)
      this.updateTemplate(record.templateId, _data)
        .then(() => {})
        .catch(() => {
          this.chancelData[index].switch = !checked
        })
    },
    /* tab切换 */
    tabChange(key) {
      this.tabKey = key
    },
    /* 编辑scenarioKey */
    EditKey() {
      this.isModify = true
      this.editPre = this.scenarioKey
    },
    cancelEdit() {
      this.isModify = false
      this.scenarioKey = this.editPre
    },
    /* 修改scenarioKey */
    modifyKey() {
      /* 发送更新业务场景请求 */
      let { scenarioKey, ...data } = this.sceneData
      this.$set(data, 'scenarioKey', this.scenarioKey)
      this.$api
        .updateScene(this.sceneId, data)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('修改成功')
            // this.reload()
            this.refresh()
          }
        })
        .catch((err) => {
          this.$message.error('修改失败')
          console.log(err, '修改错误')
        })
      this.isModify = false
    },
    /* 编辑模板 */
    editTemplate(record) {
      this.templateData = record
      this.templateVisible = true
    },
    /* 添加场景按钮 */
    addScene() {
      this.sceneData = {}
      this.sceneVisible = true
    },
    /* 编辑场景 */
    EditScene(record) {
      console.log(record, 'record')
      this.sceneData = record
      this.sceneVisible = true
    },

    /* 删除场景按钮 */
    delScene() {
      this.delSceneVisible = true
    },
    /* 删除变量 */
    delVariable(record) {
      let _this = this
      this.$confirm({
        title: '你确定要删除这条变量吗？',
        onOk() {
          /* 发送删除变量请求 */
          _this.delVariableOfScene(record.variableId)
        },
        onCancel() {},
      })
    },
    /* 删除个性化设置 */
    delIndividuation(record) {
      let _this = this
      this.$confirm({
        title: '你确定要删除这条个性化设置吗？',
        onOk() {
          _this.$api
            .deleteCustomization(record.customizationId)
            .then((res) => {
              if (res.code === 0) {
                _this.$message.success('删除成功')
                // _this.reload()
                _this.refresh()
              }
            })
            .catch((err) => {
              _this.$message.error('删除失败')
              console.log(err, '删除失败')
            })
        },
        onCancel() {},
      })
    },
    /* 添加个性化设置 */
    addIndividuation() {
      this.individuationRecord = {}
      this.individuationVisible = true
    },
    /* 编辑格式化设置 */
    editIndividuation(record) {
      this.individuationRecord = record

      this.individuationVisible = true
    },
    /* 添加分组 */
    addGroup() {
      this.isEditGroup = false
      this.currentGroup = {}
      this.groupVisible = true
    },
    /* 编辑分组 */
    editGroup(record) {
      console.log(record, 'record')
      this.currentGroup = record
      this.isEditGroup = true
      this.groupVisible = true
    },
    /* 删除分组 */
    delGroup() {
      this.delGroupVisible = true
    },
    /* 分配场景 */
    allocation() {
      this.allocationVisible = true
    },
    /* 添加变量 */
    addVariable() {
      this.variableVisible = true
    },
    /* 个性化关联场景 */
    allot(record) {
      /* 当前个性化已关联的场景id数据 */
      let { scenarioIds, ...data } = record
      scenarioIds = [...scenarioIds, this.sceneId]

      let _this = this
      let data2 = { ...data, scenarioIds }
      this.$confirm({
        title: '确定将该个性化配置分配给当前场景？',
        onOk() {
          _this
            .updateCustomizationOfScene(data2)
            .then(() => {
              _this.$message.success('分配成功')
              // _this.reload()
              _this.refresh()
            })
            .catch((err) => {
              console.log(err)
              _this.$message.error('分配失败')
            })
        },
        onCancel() {},
      })
    },
    /* 取消个性化关联场景 */
    unassign(record) {
      let { scenarioIds, ...data } = record
      scenarioIds = scenarioIds.filter((item) => {
        return item != this.sceneId
      })
      let data2 = { ...data, scenarioIds }
      data2 = this.cleanEmpty(data2)
      let _this = this
      this.$confirm({
        title: '确定将该个性化配置分配给当前场景？',
        onOk() {
          _this
            .updateCustomizationOfScene(data2)
            .then(() => {
              _this.$message.success('取消分配成功')
              // _this.reload()
              _this.refresh()
            })
            .catch(() => {
              _this.$message.error('取消分配失败')
            })
        },
        onCancel() {},
      })
    },
    cleanEmpty(object) {
      return Object.keys(object)
        .filter((key) => {
          return (
            object[key] !== ' ' &&
            object[key] !== null &&
            object[key] !== undefined &&
            object[key] !== ''
          )
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr]: object[curr] }
        }, {})
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/CSS/global.less';
.businessNotification {
  height: 99%;
  width: 100%;
  display: flex;
  flex-direction: columns;
  justify-content: space-between;
  .menu {
    width: 220px;
  }
  .content {
    width: calc(100% - 460px);
    border-radius: 3px;
    border: 1px #e8e8e8 solid;
    overflow: hidden;
    padding: 10px 20px 10px 20px;
    background-color: #fff;

    .content_key {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      background-color: #fff;
      div {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          // font-size: 20px;
        }
      }
    }
  }
  .content_tab {
    background-color: #fff;
    margin-top: 5px;
    .button {
      margin: 5px;
      margin-left: 10px;
    }
  }
}

/deep/ .ant-table-row-cell-break-word {
  padding-top: 15px;
  padding-bottom: 15px;
}

.scenarioKey {
  position: relative;
  margin-right: 20px;
  margin-left: 15px;
  width: 200px;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #151515;
  /* 伪类 */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    border-radius: 3px;
    background: #1890ff;
  }
}

.editKey {
  position: absolute;
  right: 0px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: #666;
}

.hidden_text {
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分省略号 */
  word-break: break-all; /* break-all(允许在单词内换行。) */
  display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
  -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-line-clamp: 2; /* 显示的行数 */
  max-height: 80rpx; /* 设置最大高度，根据行高，要几行乘以几倍 */
}
</style>
