<template>
  <a-modal
    :visible="delGroupVisible"
    title="删除分组"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item
        label="分组名称"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-select
          @change="selectChange"
          v-decorator="[
            'groupId',
            {
              rules: [{ required: true, message: '选择分组' }],
              initialValue: groupId,
            },
          ]"
        >
          <a-select-option
            v-for="item in groupList"
            :key="item.groupId"
            :value="item.groupId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'DelGroup',
  inject: ['reload'],
  props: {
    delGroupVisible: {
      type: Boolean,
      default: false,
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    /* 删除分组 */
    delGroup(id) {
      return new Promise((resolve, reject) => {
        this.$api
          .deleteBusinessNotificationGroup(id)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let _this = this
          this.$confirm({
            title: '你确定要删除这条分组？',
            onOk() {
              _this.delGroup(values.groupId).then(() => {
                _this.$message.success('删除成功')
                _this.$emit('refresh')
              })
            },
            onCancel() {},
          })
        }
      })
    },
    handleCancel() {
      this.$emit('update:delGroupVisible', false)
    },
    selectChange() {},
    /* 保留局部刷新前的位置状态方法 */
    savePosition() {
      let position = {
        groupMenuKey: false,
        sceneMenuKey: false,
        tabKey: 1,
        scenarioKey: ' ',
      }

      this.$store.commit('notification/savePosition', position)
    },
  },
  beforeDestroy() {
    this.savePosition()
  },
}
</script>

<style lang="less" scoped></style>
