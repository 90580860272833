<template>
  <a-modal
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    ok-text="保存"
    width="850px"
  >
    <div slot="title">{{ title }}</div>
    <!-- 穿梭框 -->
    <!-- <a-transfer
      :titles="['选择租户', '已选择租户']"
      :data-source="allocationData"
      show-search
      :filter-option="filterOption"
      :target-keys="targetKeys"
      :render="(item) => `${item.rentname}(${item.id})`"
      :rowKey="(record) => record.id"
      @change="handleChange"
      @search="handleSearch"
      :list-style="{
        width: '350px',
        height: '500px',
      }"
    /> -->

    <a-transfer
      :titles="['选择租户', '已选择租户']"
      :data-source="allocationData"
      :target-keys="targetKeys"
      show-search
      :filter-option="filterOption"
      @change="handleChange"
      :rowKey="(record) => record.id"
      :list-style="{
        width: '350px',
        height: '950px',
      }"
    >
      <template
        slot="children"
        slot-scope="{
          props: {
            direction,
            filteredItems,
            selectedKeys,
            disabled: listDisabled,
          },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          @change="tenantTableChange"
          :pagination="pagination"
          :row-selection="
            getRowSelection({
              disabled: listDisabled,
              selectedKeys,
              itemSelectAll,
              itemSelect,
            })
          "
          :columns="nameColumns"
          :data-source="filteredItems"
          :custom-row="
            ({ key, disabled: itemDisabled }) => ({
              on: {
                click: () => {
                  if (itemDisabled || listDisabled) return
                  itemSelect(key, !selectedKeys.includes(key))
                },
              },
            })
          "
        >
          <template slot="rentname" slot-scope="text, record">
            {{ text || record.name }} ({{ record.id }})
          </template>
        </a-table>
      </template>
    </a-transfer>
  </a-modal>
</template>

<script>
import _ from 'lodash'
const difference = _.difference

const nameColumns = [
  {
    title: 'name',
    dataIndex: 'rentname',
    scopedSlots: { customRender: 'rentname' },
  },
]

export default {
  name: 'Allocation',
  inject: ['reload'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'XXX',
    },
    scenarioId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      allocationData: [],
      targetKeys: [],
      alreadyTenantIdArr: [],
      alreadyTenantArr: [],
      nameColumns,
      pagination: {
        current: 1, // 当前页数
        pageSize: 10, // 当前页显示数据的大小
        total: 0,
        size: 'small',
      },
    }
  },
  created() {
    // this.getTenantList().then(() => {
    //   this.getAlreadyAllocationTenantList()
    // })
    this.init()
  },
  methods: {
    init() {
      /* 分页获取租户 */
      this.getTenantInfoList({
        page: this.pagination.current,
        size: this.pagination.pageSize,
      }).then(() => {
        /* 获取已分配的租户 */
        this.getAlreadyAllocationTenantList()
      })
    },
    tenantTableChange({ pageSize, current }) {
      // console.log(pagination)
      this.getTenantInfoList({ page: current, size: pageSize })
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys)
          itemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected)
        },
        selectedRowKeys: selectedKeys,
      }
    },
    /* 获取租户列表列表 */
    getTenantInfoList(params) {
      // let params = {
      //   page: this.pagination.current,
      //   size: this.pagination.pageSize,
      // }

      return new Promise((resolve, reject) => {
        /* 所有租户 */

        this.$api
          ._getTenantInfoList(params)
          .then((res) => {
            if (res.code == 0) {
              this.allocationData = res.data.content
              this.pagination.total = res.data.totalElements
              // this.count.totalCount = res.data.totalElements
              this.pagination.current = res.data.number + 1
              resolve()
            }
          })
          .catch(() => {
            reject()
          })
      })
    },

    /* ---------------------------------------------------- */

    /* 获取用户列表 */
    getTenantList() {
      return new Promise((resolve, reject) => {
        this.$api
          .getAllTenantList()
          .then((res) => {
            if (res.code === 0) {
              this.allocationData = res.data
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 获取该场景下已分配的用户列表 */
    getAlreadyAllocationTenantList() {
      return new Promise((resolve, reject) => {
        this.$api
          .getAlreadyAllocationTenantList(this.scenarioId)
          .then((res) => {
            if (res.code === 0) {
              this.alreadyTenantArr = res.data
              this.alreadyTenantIdArr = res.data.map((item) => {
                return (item = item.tenantId)
              })
              this.targetKeys = this.alreadyTenantIdArr
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 确认 */
    async handleOk() {
      let isAllocation = false
      let isUnAllocation = false
      let isSuccess1 = true
      let isSuccess2 = true

      /* 分配场景 */
      /* 筛选需要targetKeys中需要分配场景的租户tenantId */
      let needAllocationId = this.targetKeys.filter((item) => {
        return !this.alreadyTenantIdArr.includes(item)
      })
      if (needAllocationId.length > 0) {
        isAllocation = true
        await this.allocationScene(needAllocationId)
          .then((res) => {
            isSuccess1 = true
          })
          .catch((err) => {
            console.log(err)
            isSuccess1 = false
          })
      }

      /* 取消分配场景 */
      /* 筛选需要取消分配场景的租户的permissionId */
      // console.log(this.alreadyTenantIdArr, '已分配的')
      let needCancelAllocation = this.alreadyTenantArr
        .filter((item) => {
          return !this.targetKeys.includes(item.tenantId)
        })
        .map((item) => {
          return (item = item.permissionId)
        })

      if (needCancelAllocation.length > 0) {
        isUnAllocation = true
        /* 所有取消分配的Promise请求完成 */
        await this.cancelAllocationScene(needCancelAllocation)
          .then((res) => {
            isSuccess2 = true
          })
          .catch((err) => {
            console.log(err)
            isSuccess2 = false
          })
      }

      /* 结果判断 */
      if (
        (isAllocation && !isSuccess1) ||
        (isUnAllocation && !isSuccess2) ||
        (!isSuccess1 && !isSuccess2)
      ) {
        this.$message.error('保存失败')
        this.$emit('refresh')
      } else {
        this.$message.success('保存成功')
        this.$emit('refresh')
      }
    },

    /* 分配场景 */
    allocationScene(tenantIds) {
      return new Promise((resolve, reject) => {
        let data = {
          scenarioId: this.scenarioId,
          permissionType: 'TENANT',
          tenantIds,
        }
        this.$api
          .sceneAllocationToTenant(data)
          .then((res) => {
            if (res.code === 0) {
              resolve(res.message)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 取消分配场景 */
    cancelAllocationScene(permissionIds) {
      return new Promise((resolve, reject) => {
        this.$api
          .cancelAllocationScene(permissionIds)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    handleCancel() {
      this.$emit('refresh')
      // this.$emit('update:visible', false)
    },
    /* 搜索筛选方法 */
    filterOption(inputValue, option) {
      if (
        option.name.indexOf(inputValue) > -1 ||
        option.id.indexOf(inputValue) > -1
      ) {
        return true
      } else {
        return false
      }
    },
    /* 穿梭时触发 */
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    /* 穿梭框搜索是触发 */
    handleSearch(direction, value) {},
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-transfer {
  padding: 0 auto !important;
}
</style>
