<template>
  <a-modal
    :visible="groupVisible"
    :title="isEdit ? '编辑分组' : '添加分组'"
    @ok="handleOk"
    @cancel="handleCancel"
    ok-text="保存"
  >
    <a-form :form="form">
      <a-form-item
        label="名称"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input
          placeholder="请填写"
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请填写分组名称' }],
              initialValue: groupInfo.name || '',
            },
          ]"
        ></a-input>
      </a-form-item>
      <!-- <a-form-item
        label="对应解决方案"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-select
          placeholder="请选择解决方案"
          v-decorator="[
            'planId',
            { rules: [{ required: true, message: '请选择方案' }] },
          ]"
        >
          <a-select-option value="bigtable">自建应用</a-select-option>
          <a-select-option
            v-for="item in planList"
            :key="item.planId"
            :value="item.planId"
            >{{ item.planName }}</a-select-option
          >
        </a-select>
      </a-form-item> -->
      <a-form-item
        label="选择上级"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-select
          placeholder="请选择上级"
          v-decorator="[
            'parentId',
            {
              rules: [{ required: true, message: '请选择上级' }],
              initialValue: groupInfo.parentId || ' ',
            },
          ]"
        >
          <a-select-option value=" ">最上级</a-select-option>
          <a-select-option
            v-for="item in groupList"
            :key="item.groupId"
            :value="item.groupId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item
        label="是否隐藏"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-switch
          checked-children="开"
          un-checked-children="关"
          v-decorator="[
            'hidden',
            {
              rules: [{ required: true }],
              initialValue: groupInfo.hidden || false,
              valuePropName: 'checked',
            },
          ]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'AddGroup',
  inject: ['reload'],
  props: {
    groupVisible: {
      type: Boolean,
      default: false,
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    // isEdit: {
    //   type: Boolean,
    //   default: false,
    // },
    groupInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      planList: [],
      isEdit: Object.keys(this.groupInfo).length > 0,
    }
  },
  created() {
    this.getPlanList()
  },
  methods: {
    /* 获取解决方案列表 */
    getPlanList() {
      this.$api.planList().then((res) => {
        // console.log(res, '解决方案')
        if (res.code === 0) {
          this.planList = res.data.map((item) => {
            return item.plan
          })
        } else {
          this.$message.error('获取解决方案失败！')
        }
      })
    },

    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = this.cleanEmpty(values)
          if (this.isEdit) {
            /* 发送修改分组请求 */
            this.$api
              .updateBusinessNotificationGroup(this.groupInfo.groupId, data)
              .then((res) => {
                this.$message.success('修改成功')
                this.$emit('refresh')
              })
          } else {
            /* 发送添加分组请求 */
            this.$api.addBusinessNotificationGroup(data).then((res) => {
              this.$message.success('添加成功')
              this.$emit('refresh')
            })
          }
        }
      })
    },
    handleCancel() {
      // this.$emit('refresh')
      this.$emit('update:groupVisible', false)
    },
    cleanEmpty(object) {
      return Object.keys(object)
        .filter((key) => {
          return (
            object[key] !== '' &&
            object[key] !== null &&
            object[key] !== undefined
          )
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr]: object[curr] }
        }, {})
    },
  },
}
</script>

<style lang="less" scoped></style>
