var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.groupVisible,"title":_vm.isEdit ? '编辑分组' : '添加分组',"ok-text":"保存"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称","label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: '请填写分组名称' }],
            initialValue: _vm.groupInfo.name || '',
          } ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: '请填写分组名称' }],\n            initialValue: groupInfo.name || '',\n          },\n        ]"}],attrs:{"placeholder":"请填写"}})],1),_c('a-form-item',{attrs:{"label":"选择上级","label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'parentId',
          {
            rules: [{ required: true, message: '请选择上级' }],
            initialValue: _vm.groupInfo.parentId || ' ',
          } ]),expression:"[\n          'parentId',\n          {\n            rules: [{ required: true, message: '请选择上级' }],\n            initialValue: groupInfo.parentId || ' ',\n          },\n        ]"}],attrs:{"placeholder":"请选择上级"}},[_c('a-select-option',{attrs:{"value":" "}},[_vm._v("最上级")]),_vm._l((_vm.groupList),function(item){return _c('a-select-option',{key:item.groupId,attrs:{"value":item.groupId}},[_vm._v(_vm._s(item.name))])})],2)],1),_c('a-form-item',{attrs:{"label":"是否隐藏","label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'hidden',
          {
            rules: [{ required: true }],
            initialValue: _vm.groupInfo.hidden || false,
            valuePropName: 'checked',
          } ]),expression:"[\n          'hidden',\n          {\n            rules: [{ required: true }],\n            initialValue: groupInfo.hidden || false,\n            valuePropName: 'checked',\n          },\n        ]"}],attrs:{"checked-children":"开","un-checked-children":"关"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }